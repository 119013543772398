<template>
    <div class="sub_add" v-if="isShow" v-wechat-title="this.title">
        <div class="top">
            <Steps :dataSource="list" :active="1" :class="short_name ==='xrb'?'steps last':'steps'"></Steps>
            <!-- <div class="remark">请您按照步骤提交申请所需要的信息～</div> -->
        </div>
        <div class="content">
            <div class="title">职称信息</div>
            <formPanel v-if="formList.length > 0" :arr="formList[formIndex].form" @confirm="formConfirm" @step="back"></formPanel>
            <!-- <van-cell-group>
                <van-field v-model="department" rows="1" autosize type="textarea">
                    <template #label>
                        <span style="margin-right:5px;color:red">*</span><span>科室</span>
                    </template>
                </van-field>
                <van-field label="职务" v-model="post" rows="1" autosize type="textarea">
                    <template #label>
                        <span style="margin-right:5px;color:red">*</span><span>职务</span>
                    </template>
                </van-field>
                <van-field label="邮箱" v-model="email" rows="1" autosize type="textarea">
                    <template #label>
                        <span style="margin-right:12px"></span><span>邮箱</span>
                    </template>
                </van-field>
                <van-field label="备注" v-model="remarks" placeholder="这里填写备用信息" rows="1" autosize type="textarea">
                    <template #label>
                        <span style="margin-right:12px"></span><span>备注</span>
                    </template>
                </van-field>
            </van-cell-group> -->
        </div>
        <!-- <div class="btn">
            <van-button round type="info" size="large" class="up" :disabled="disabled" @click="next">保存进入下一步
            </van-button>
        </div>
        <div class="goBack" @click="back">
            <img src="/static/goBack.png" />
        </div> -->
    </div>
</template>

<script>
    import {
        Toast
    } from "vant";
    import {
        StepTwo,
        projectDoctor,
        getDoctorForm
    } from '@/api/api'
    import Steps from '@/components/steps.vue'
    import formPanel from '@/components/form.vue'
    import {
        mapActions,
        mapGetters,
        mapState
    } from 'vuex';
    import {
        checkCellphone,
        isIdCardNo,
        checkMail
    } from '@/utils/utils'
    export default {
        name: '',
        components: {
            Steps,
            formPanel
        },
        data() {
            return {
                title: ' ',
                isShow: false,
                showAddress: false,
                showHospital: false,
                department: '',
                project_doctor_id: null,
                post: '',
                email: '',
                remarks: '',
                addressList: [],
                hospitalList: [],
                list: [{
                    step_name: '身份信息',
                }, {
                    step_name: '职称信息'
                }, {
                    step_name: '上传证件'
                }, {
                    step_name: '上传身份证'
                }, {
                    step_name: '签署协议'
                }],
                formList: [],
                formIndex: 1,
                btnDisabled: false,
                dire: 'front', // 前进:front;后退:back
                short_name:''
            }
        },
        computed: {
            disabled() {
                return !(this.post && this.department)
            },
            ...mapGetters(['sub_id']),
            ...mapState(['basicInformation'])
        },
        mounted() {
            this.dire = this.$route.query.dire
            this.project_doctor_id = this.$route.query.project_doctor_id
            this.short_name = this.basicInformation.projects[0].short_name
            // 新瑞白科研项目、慢性常见病学科发展项目，显示知情同意书
            if(['xrb', 'sshy'].includes(this.short_name)){
                this.list[4].step_name = `阅读知情同意书`
            }
            this.init()
        },
        methods: {
            ...mapActions(['SET_ADD_INFORMATION']),
            async init() {
                let res = await projectDoctor()
                let {
                    department,
                    email,
                    job,
                    remark,
                    doctor
                } = res
                this.department = department || ""
                this.email = email || ""
                this.post = job || ""
                this.remarks = remark || ""
                const data = await getDoctorForm({
                    params: {
                        doctor_id: doctor.id,
                        subproject_id: this.sub_id
                    }
                })
                this.formList = data
                for (let i in this.formList) {
                    let ele = this.formList[i]
                    if (ele.form.length>0) {
                        for (let j in ele.form) {
                            let e = ele.form[j]
                            if (!e.value) {
                                e.readonly = 0
                            }
                        }
                    }
                }
                if (this.formList[1].form.length<1) {
                    let req = {}
                    if (this.dire == 'front') this.next(req)
                    if (this.dire == 'back') this.back()
                } else {
                    for (let i in data) {
                        if (this.formList[i].form.length<1) {
                            this.list.splice(i, 1)
                        }
                    }
                    this.title = '职称信息'
                    this.isShow = true
                }
            },
            formConfirm(value) {
                if (this.btnDisabled) {
                    return
                }
                let req = {}
                for(let i in value) {
                    let ele = value[i]
                    req[ele.name] = ele.value
                }
                this.btnDisabled = true
                this.next(req)
            },
            onConfirm() {

            },
            back() {
                this.$router.replace({
                    path: '/add_hospital',
                    query: {
                        project_doctor_id: this.project_doctor_id,
                        dire: 'back'
                    }
                })
            },
            next(req) {
                // if (this.email && !checkMail(this.email)) {
                //     Toast('请输入正确的邮箱')
                //     return false
                // }
                req.project_doctor_id = this.project_doctor_id
                StepTwo(req).then(res => {
                    this.$router.replace({
                        path: '/add_idsn',
                        query: {
                            project_doctor_id: this.project_doctor_id,
                            dire: 'front'
                        }
                    })
                    // this.btnDisabled = false
                }).catch(err => {
                    this.btnDisabled = false
                    Toast(err.response.data.message)
                })

            }
        }
    }
</script>

<style lang="scss" scoped>
    .sub_add {
        height: 100%;
        width: 100%;

        .top {
            width: 100%;
            height: 97px;
            background-image: linear-gradient(to right, #5B9BF0, #3D87EA);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 14px;

            .steps {
                width: 100%;
                margin-bottom: 17px;
            }

            .remark {
                width: 243px;
                height: 21px;
                background: #006AE1;
                border-radius: 10px;
                font-size: 11px;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 21px;

            }
        }

        .content {

            .title {
                font-size: 15px;
                color: #232323;
                text-align: left;
                padding-left: 16px;
                box-sizing: border-box;
                margin-bottom: 10px;
            }
        }


    }
</style>